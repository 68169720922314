import React from 'react';
import './ControlPanel.css';
import {
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { AiFillSound } from "react-icons/ai";


const ControlPanel = ({ enableAIVoice, setEnableAIVoice }) => {
  const toggleAIVoice = () => {
    setEnableAIVoice(!enableAIVoice);
  };

  return (
    <div className="control-panel">
      <button className={`toggle-btn ${enableAIVoice ? 'active' : ''}`} onClick={toggleAIVoice}>
      <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id="button-tooltip-2">
              Toggle AI speech
            </Tooltip>
          }
        >
          <div className="">
            <AiFillSound size={16} style={{}} />
          </div>
        </OverlayTrigger>
      </button>
      {/* Add more toggle buttons as needed */}
    </div>
  );
};

export default ControlPanel;
