import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { createClient } from "@supabase/supabase-js";
// import { useNavigate } from "react-router-dom";
import "./AuthScreen.css";
import Header from "./Header";
import { AuthContext, AuthProvider } from "./AuthContext";

const AuthScreen = ({setView}) => {
  // const navigate = useNavigate();
  const [isSignUp, setIsSignUp] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const { loading, supabase, authenticated, user, profile, setProfile, setUser, setActiveSession, setSessionHasBeenSet } = React.useContext(AuthContext);

  const fieldsAreNotEmpty = () => {
    if (isSignUp) {
      return email !== "" && password !== "";
    } else {
      return email !== "" && password !== "";
    }
  };

  const handleSubmit = async (e) => {
    //console.log('here')
    e.preventDefault();
    setError(null);

    const response = isSignUp
      ? await supabase.auth.signUp({ email, password })
      : await supabase.auth.signInWithPassword({ email, password });

    if (response.error) {
      setError(response.error.message);
    } else {

      // Navigate to home page or wherever you want the user to go after logging in
      setUser(response.data.user)
      setActiveSession(response.data.session);
      setSessionHasBeenSet(true);

    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6} className="auth-col">
          <Header setView={() => {}}></Header>
          <h2>{isSignUp ? "Sign Up" : "Log In"}</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>
                Email address <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPassword">
              <Form.Label>
                Password <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={!fieldsAreNotEmpty()}
            >
              {isSignUp ? "Sign Up" : "Log In"}
            </Button>
          </Form>
          <p
            className="mt-3"
            style={{ cursor: "pointer" }}
            onClick={() => setIsSignUp(!isSignUp)}
          >
            {isSignUp ? (
              <>
                Already have an account?{" "}
                <span style={{ color: "#007BFF" }}>Log in</span>
              </>
            ) : (
              <>
                Don't have an account?{" "}
                <span style={{ color: "#007BFF" }}>Sign up</span>
              </>
            )}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AuthScreen;
