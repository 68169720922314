import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import GameInfoStart from "./GameInfoStart";
import Header from "./Header";
// import { getGames } from "../Utility";
import { useToast } from './ToastComponent';
import "./AllGames.css";
import { AuthContext, AuthProvider } from "./AuthContext";

const AllGames = ({setView}) => {
  const { showToast } = useToast();
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { undefined, supabase, authenticated, user, profile, setProfile } = React.useContext(AuthContext);

  useEffect(() => {
      if (!games.length > 0) {
        getGames();
      }

    // getGames()
    //   .then((games) => {
    //     setGames(games);
    //     setLoading(false);
    //     //console.log(games);
    //   })
    //   .catch((error) => {
    //     //console.log("error: ", error);
    //     setError(error);
    //     setLoading(false);
    //   });
  }, []);

  const getGames = async () => {
    let { data, error } = await supabase
    .from('game')
    .select('*')
    .eq('draft', false)

    if (error) {
      console.log('error fetching games', error);
      showToast('Unable to fetch games, please contact support.', 'danger');
      return;
    }

    //console.log('data getGames', data);

    setGames(data)
    setLoading(false);
  }

  // const navigate = useNavigate();

  const handleGameClick = (game) => {
    const newProps = { gameId: game.id };
    setView("gameInfoStart", newProps)
    // navigate("/game-info-start/", {
    //   state: { gameId: game.id},
    // });
  };

  const ratingStars = (rating) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<FaStar key={i} color="#ffc107" />);
    }
    return stars;
  };

  return (
    <div className="all-games">
      <Header setView={setView}/>
      <div className="games-list">
        {loading ? (
          <div className="spinner-container">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          games.map((game) => (
            <Card
              className="game-card"
              onClick={() => handleGameClick(game)}
              key={game.id}
            >
              {/* <Card.Img variant="top" src={game.image} alt={game.name} /> */}
              <Card.Body style={{ width: "100%" }}>
                <Card.Title>{game.name}</Card.Title>
                <Card.Text>{game.description}</Card.Text>
                {/* {game.rating != null ? (
                <Row>
                  <Col xs={6} className="text-left">
                    {ratingStars(game.rating)}
                  </Col>
                  <Col xs={6} className="text-right">
                    <span>{game.rating.toFixed(1)}</span>
                  </Col>
                </Row>)} */}
              </Card.Body>
            </Card>
          ))
        )}
      </div>
    </div>
  );
};

export default AllGames;
