// HomePage.js
import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { GiGamepad } from "react-icons/gi";
import { IoPersonCircleSharp, IoSettingsSharp } from "react-icons/io5";
import { MdBuild } from "react-icons/md";
import "./HomePage.css";
import Header from "./Header";
import { AuthContext, AuthProvider } from "./AuthContext";

const HomePage = ({ setView }) => {
  const { undefined, supabase, authenticated, user, profile, setProfile } =
    React.useContext(AuthContext);


  // if (!profile) {
  //   setView('profile');
  // }

  //

  const menuItems = [
    { title: "Play", viewName: "allGames", icon: <GiGamepad size={48} /> },
    // { title: "My Profile", viewName: "profile", icon: <IoPersonCircleSharp size={48} /> },
    // { title: "Settings", viewName: "settings", icon: <IoSettingsSharp size={48} /> }
    { title: "Creator Studio", viewName: "creatorAllGames", icon: <MdBuild size={48} /> },
  ];

  return (
    <Container className="homepage-container">
      <Header setView={setView}/>
      <Row style={{margin: 0}}>
        {
          menuItems.map((item, index) => (
            ((item.viewName == 'creatorAllGames' && profile.creator) || (item.viewName != 'creatorAllGames')) &&
            <Col xs={12} sm={6} md={4} key={index} className="menu-item-col">
              <Card
                className="menu-item-card"
                onClick={() => setView(item.viewName)}
              >
                <Card.Body>
                  <Card.Title className="menu-item-title">
                    {item.title}
                  </Card.Title>
                  <div className="menu-item-icon">{item.icon}</div>
                </Card.Body>
              </Card>
            </Col>
          ))
        }
      </Row>
    </Container>
  );
};

export default HomePage;
