import React, { createContext, useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URI;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_AUTH_TOKEN;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  console.log("⚡️ | file: AuthContext.js:12 | AuthProvider | loading:", loading);

  const [authenticated, setAuthenticated] = useState(false);
  console.log("⚡️ | file: AuthContext.js:15 | AuthProvider | authenticated:", authenticated);

  const [user, setUser] = useState(null);
  console.log("⚡️ | file: AuthContext.js:18 | AuthProvider | user:", user);

  const [profile, setProfile] = useState(null);
  console.log("⚡️ | file: AuthContext.js:21 | AuthProvider | profile:", profile);

  const [activeSession, setActiveSession] = useState(localStorage.getItem("session") ? JSON.parse(localStorage.getItem("session")) : null);
  console.log("⚡️ | file: AuthContext.js:21 | activeSession | activeSession:", activeSession);

  const [sessionHasBeenSet, setSessionHasBeenSet] = useState(false);



  const TOKEN_REFRESH_THRESHOLD = 5 * 60 * 1000; // 5 minutes in milliseconds 5 * 60 * 1000

  // useEffect(() => {
  //   console.log('ac ue running')
  //   const checkAuth = async () => {
  //     console.log('checkAuth')

     

  //   };

  //   const fetchProfile = async () => {
  //     let { data: profile, error } = await supabase
  //       .from('profile')
  //       .select("*")
  //       .eq('id', user.id)

  //     if (error) {
  //       //console.log('profile fetch error', error)
  //     } else {
  //       //console.log('profile data', profile)
  //       setProfile(profile[0]);
  //     }

  //     setLoading(false);
  //   }

  //   checkAuth();

  //   // if (authenticated && !profile) {
  //   //   fetchProfile();
  //   // } else {
  //   //   setLoading(false);
  //   // }


  //   const refreshTimer = setInterval(checkAuth, TOKEN_REFRESH_THRESHOLD);
  //   return () => clearInterval(refreshTimer);
  // }, [profile, accessToken, refreshToken]);

  useEffect(() => {
    if (profile) {
      setLoading(false);
    }
  }, [profile]);
  
  useEffect(() => {
    setLoading(true);

    const configureSupabase = async () => {
      // Set the session with the tokens
      const { data, error: sessionError } = await supabase.auth.setSession({
        access_token: activeSession.access_token,
        refresh_token: activeSession.refresh_token,
      });

      //console.log("data", data);

      if (sessionError) {
        console.error('sessionError', sessionError)
        // Session could not be set, log user out
        setActiveSession(undefined);
        return;
      }

      
      setActiveSession(data.session)
      setSessionHasBeenSet(true);
      setUser(data.user);
    }

    if (!activeSession) {
      localStorage.removeItem("session");
      setAuthenticated(false);
      setLoading(false);
      setSessionHasBeenSet(false);
    } else {
      localStorage.setItem("session", JSON.stringify(activeSession));
      if (!sessionHasBeenSet) {
        configureSupabase();
      } else {
        const fetchProfile = async () => {
          let { data: profile, error } = await supabase
            .from('profile')
            .select("*")
            .eq('id', user.id)
    
          if (error) {
            console.log('profile fetch error', error)
          } else {
            console.log('profile data', profile)
            if (profile[0]) {
              setProfile(profile[0]);
            } else {
              setLoading(false);
            }
          }
    
        }

        if (!profile) {
          fetchProfile();
        } else {
          setLoading(false);
        }
        
        setAuthenticated(true);
      }
    }
    // if (activeSession) {

      

      
      
      // const handleRefresh = async () => {
      //   const expiresIn = activeSession.session.expires_at * 1000 - Date.now();

      //   //console.log("ms before refresh ", expiresIn - TOKEN_REFRESH_THRESHOLD);

      //   if (expiresIn < TOKEN_REFRESH_THRESHOLD) {
      //     //console.log("token expires in 5 min, refreshing");
      //     // Access token is about to expire, refresh it
      //     const { data, error: refreshError } =
      //       await supabase.auth.refreshSession();

      //     if (refreshError) {
      //       //console.log("refresh error ", refreshError);
      //       // Refresh token is invalid, log user out
      //       localStorage.removeItem("access_token");
      //       localStorage.removeItem("refresh_token");
      //       setAuthenticated(false);
      //       setLoading(false);
      //       return;
      //     }

      //     setAccessToken(data.session.access_token)
      //     setRefreshToken(data.session.refresh_token)
      //     setUser(data.user);

      //     if (!data.session.access_token) {
      //       // Access token could not be retrieved, log user out
      //       localStorage.removeItem("access_token");
      //       localStorage.removeItem("refresh_token");
      //       setAuthenticated(false);
      //       setLoading(false);
      //       return;
      //     }
      //   }

      //   setAuthenticated(true);
      // }

    // }
  }, [activeSession]);

  // useEffect(() => {
  //   if (refreshToken) {
  //     localStorage.setItem("access_token", refreshToken);
  //   }
  // }, [refreshToken]);

  return (
    <AuthContext.Provider value={{ loading, supabase, authenticated, user, profile, setProfile, setUser, setActiveSession, setSessionHasBeenSet }}>
      {children}
    </AuthContext.Provider>
  );
};
