// src/components/ToastComponent.js
import React, { useState, useEffect, useContext, createContext } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

const ToastContext = createContext();

export const useToast = () => {
  return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState({ show: false, message: 'test', variant: 'success' });

  const showToast = (message, variant = 'success') => {
    setToast({ show: true, message, variant });
  };

  const hideToast = () => {
    setToast({ ...toast, show: false });
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      <div className="toast-container">
        <Toast
          onClose={hideToast}
          show={toast.show}
          animation
          delay={3000}
          autohide
          style={{
            position: 'fixed',
            top: 20,
            left: '50%',
            transform: 'translateX(-50%)',
            minWidth: 200,
            zIndex: 9999,
          }}
        >
          {/* <Toast.Header closeButton={false}>
            <strong className="mr-auto">Notification</strong>
          </Toast.Header> */}
          <Toast.Body className={`text-${toast.variant}`}>{toast.message}</Toast.Body>
        </Toast>
        {children}
      </div>
    </ToastContext.Provider>
  );
};
