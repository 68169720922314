import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FiMic, FiPause, FiAlertCircle } from "react-icons/fi";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import "./UserInput.css";

const UserInput = ({handleChange, handleSendMessage}) => {
  const textareaRef = useRef(null);
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const [inputText, setInputText] = useState("");
  const [lastText, setLastText] = useState("");
  
  useEffect(() => {
    if (transcript) {
      setInputText(lastText ? lastText + " " + transcript : transcript);
    }
  }, [transcript]);

  useEffect(() => {
    if (listening) {
      if (lastText == "") {
        setLastText(inputText);
      }
    } else {
      setLastText("");
      resetTranscript();
    }
  }, [listening]);

  useEffect(() => {
    handleChange(inputText);
  }, [inputText]);

  const handleManualInput = (event) => {
    setInputText(event.target.value);
  };

  const startContinuousListening = () => {
    SpeechRecognition.startListening({ continuous: true }); //{ continuous: true }
  };

  const handleSend = () => {
    SpeechRecognition.stopListening();
    handleSendMessage();
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSend();
    }
  };


  if (!browserSupportsSpeechRecognition) {
    return (
      <>
        <Row className="user-input-container">
          <Col>
            <Form>
              <Form.Control
                as="textarea"
                rows={5}
                value={inputText}
                className="user-input-textarea"
                onChange={handleManualInput}
                ref={textareaRef}
                onKeyDown={handleKeyDown}
              />
            </Form>
          </Col>
          <Col xs="auto" className="user-input-controls">
            <span style={{ position: "relative" }}>
              <Button className="user-input-record-button" disabled>
                <FiMic size={24} />
              </Button>
              <div
                style={{
                  zIndex: 999,
                  position: "absolute",
                  top: "-12px",
                  right: "-5px",
                  color: "red",
                  cursor: "pointer",
                }}
              >
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Chrome browser is required for voice to speech functionality
                    </Tooltip>
                  }
                >
                  <div className="">
                    <FiAlertCircle size={16} style={{}} />
                  </div>
                </OverlayTrigger>
              </div>
            </span>

           
          </Col>
        </Row>
        <Button variant="primary" onClick={handleSend} className="send-button">
          Send
        </Button>
      </>
    );
  }

  return (
    <>
      <Row className="user-input-container">
        <Col>
          <Form>
            <Form.Control
              as="textarea"
              rows={5}
              disabled={listening}
              value={inputText}
              className="user-input-textarea"
              onChange={handleManualInput}
              ref={textareaRef}
              onKeyDown={handleKeyDown}
            />
          </Form>
        </Col>
        <Col xs="auto" className="user-input-controls">
          {!listening && (
            <Button
              onClick={startContinuousListening}
              className="user-input-record-button"
              disabled={listening}
            >
              <FiMic size={24} />
            </Button>
          )}
          {listening && (
            <Button
              onClick={SpeechRecognition.stopListening}
              className="user-input-pause-button"
              disabled={!listening}
            >
              <FiPause size={24} />
            </Button>
          )}
        </Col>
      </Row>
      <Button variant="primary" onClick={handleSend} className="send-button">
        Send
      </Button>
    </>
  );
};

export default UserInput;
