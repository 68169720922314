import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Accordion,
  Card,
} from "react-bootstrap";
import { FiPlus, FiMinus, FiArrowUp, FiArrowDown } from "react-icons/fi";
import Header from "./Header";
import "./UpsertGame.css";
import GameScreen from "./GameScreen";
import Loader from "./Loader";
import { getGameAndSteps } from "../Utility";
import { AuthContext, AuthProvider } from "./AuthContext";
import { useToast } from "./ToastComponent";

const UpsertGame = ({ setView, gameId }) => {
  //console.log("UpsertGame.gameId", gameId);
  const { showToast } = useToast();
  const { undefined, supabase, authenticated, user, profile, setProfile } =
    React.useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [deletedSteps, setDeletedSteps] = useState([]);
  const [game, setGame] = useState({
    name: "",
    description: "",
    instructions: "",
    draft: true,
    id: undefined,
    game_steps: [
      {
        id: undefined,
        step_name: "Mandatory Command Step",
        order: 1,
        prompt: "",
        role: "system",
        game_id: undefined,
      },
    ],
  });

  useEffect(() => {
    if (gameId != null) {
      setLoading(true);
      supabase
        .from("game")
        .select(
          `
        *,
        game_steps (
          *
        ).
      `
        )
        .eq("id", gameId)
        .then((res) => {
          //console.log("success", res);
          let gameFetched = res.data[0];
          let game_steps = gameFetched.game_steps.sort(
            (a, b) => a.order - b.order
          );
          gameFetched.game_steps = game_steps;
          setGame(gameFetched);
        })
        .catch((error) => {
          console.log("error", error);
          showToast(
            "Unable to fetch game and steps, please contact support.",
            "danger"
          );
        });
    }
  }, [gameId]);

  useEffect(() => {
    if (gameId) {
      if (game) {
        if (game.game_steps) {
          setLoading(false);
        }
      }
    }
  }, [game]);

  if (loading) {
    return <Loader />;
  }

  const handleTestGame = () => {
    setShowTestModal(true);
  };

  const closeTestModal = () => {
    setShowTestModal(false);
  };

  const convertToGameFormat = (gameStepsArray) => {
    if (!Array.isArray(gameStepsArray) || gameStepsArray.length === 0) {
      return null;
    }

    const gameInfo = {
      name: gameStepsArray[0].game_name,
      description: gameStepsArray[0].game_description,
      instructions: gameStepsArray[0].game_instructions,
      id: gameStepsArray[0].id,
      steps: [],
    };

    gameStepsArray.forEach((step) => {
      gameInfo.steps.push({
        id: step.id,
        step_name: step.step_name,
        order: step.step_order,
        prompt: step.step_prompt,
        role: step.step_role,
        game_id: step.game_id,
      });
    });

    return gameInfo;
  };

  const getGameAndStepsFormat = () => {
    //console.log("getGameAndStepsFormat", game);
    /**
     *  game_description: "A game that will allow you to practice responding to outlandish lines"
        game_id: 1
        game_image: null
        game_instructions: "The game will start with the user giving the first line of the scene or conversation.\nThe user will then act as the \"voice of reason,\" using logical arguments, facts, and calm reasoning to try to persuade the computer character to act rationally.\n The computer character will act irrational or emotional, making it challenging for the user to bring order to the conversation.\nThe scene should last for 2-3 minutes.\nThe user should try to outwit the computer character with their reasoning skills and try to bring order to the conversation."
        game_name: "Practice voice of reasoning"
        step_id: 5
        step_name: "Setup"
        step_order: 1
        step_prompt: "Let's play a game.  I'll provide a sample line of dialogue that could be used to start an improv comedy scene.  I'd like you to respond with something shocking.  We'll each take turns responding to each other’s sample lines of dialogue until I type END. You are not allowed to type end, only me."
        step_role: "system"
     * */

    return game.game_steps.map((step) => {
      return {
        game_description: game.description,
        game_id: game.id,
        game_instructions: game.instructions,
        game_name: game.name,
        step_id: step.id,
        step_name: step.step_name,
        step_order: step.order,
        step_prompt: step.prompt,
        step_role: step.role,
      };
    });
  };

  const addStep = (role) => {
    let order = game.game_steps.length + 1;
    setGame((prevGame) => ({
      ...prevGame,
      game_steps: [
        ...prevGame.game_steps,
        {
          step_name: role.charAt(0).toUpperCase() + role.slice(1) + " Step",
          order: order,
          role: role,
          prompt: "",
        },
      ],
    }));
  };

  const updateStep = (index, key, value) => {
    setGame((prevGame) => {
      const updatedSteps = [...prevGame.game_steps];
      updatedSteps[index][key] = value;
      return { ...prevGame, game_steps: updatedSteps };
    });

    //console.log("updateStep step", game);
  };

  const removeStep = (index) => {
    setGame((prevGame) => {
      // Find the removed step
      const removedStep = prevGame.game_steps[index];

      // Add the removed step to the setDeletedSteps state
      setDeletedSteps((prevDeletedSteps) => [...prevDeletedSteps, removedStep]);

      // Remove the step from the game_steps array
      return {
        ...prevGame,
        game_steps: prevGame.game_steps.filter((_, idx) => idx !== index),
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGame((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isFormValid = () => {
    const gameValid =
      game.name.trim() !== "" &&
      game.description.trim() !== "" &&
      game.instructions.trim() !== "";

    const stepsValid = game.game_steps.every(
      (step) => step.step_name.trim() !== "" && step.role.trim() !== ""
      //&& step.prompt.trim() !== ""
    );

    return gameValid && stepsValid && game.game_steps.length > 0;
  };

  // const handleSaveDraft = async () => {
  //   //console.log('gameToUpsert', game);
  //   let gameToUpsert = game;
  //   let steps = game.game_steps;
  //   gameToUpsert.draft = true;
  //   delete gameToUpsert.game_steps;
  //   if (!gameToUpsert.id) {
  //     delete gameToUpsert.id;
  //   }
  //   //console.log('gameToUpsert', gameToUpsert);
  //   let upsertedGame = await supabase.from('game').insert(gameToUpsert, { upsert: true }).select().then((res) => {
  //     //console.log('success', res)
  //   }).catch((error) => {
  //     //console.log('error', error)
  //   })
  //   try {
  //     upsertedGame = upsertedGame.data[0];
  //     //console.log('game upserted', upsertedGame)
  //     handleSaveSteps(upsertedGame, steps);
  //   } catch (error) {
  //     //console.log('Error upserting game');
  //     showToast('Unable to create game, please contact support.', 'danger');
  //   }
  // };

  const handleSaveGame = async (draft) => {
    setLoading(true);
    //console.log("gameToUpsert", game);
    let gameToUpsert = game;
    let steps = game.game_steps;
    gameToUpsert.draft = draft;
    delete gameToUpsert.game_steps;
    let upsertedGame;

    if (!gameToUpsert.id) {
      delete gameToUpsert.id;
      // Game insert
      const { data, error } = await supabase
        .from("game")
        .insert(gameToUpsert)
        .select();
      if (error) {
        console.log("Error inserting game", error);
        showToast("Unable to create game, please contact support.", "danger");
        return;
      }
      upsertedGame = data[0];
    } else {
      // Game update
      const { data, error } = await supabase
        .from("game")
        .update(gameToUpsert)
        .eq("id", gameToUpsert.id)
        .select();
      if (error) {
        console.log("Error updating game", error);
        showToast("Unable to update game, please contact support.", "danger");
        return;
      }
      //console.log("data", data);
      upsertedGame = data[0];
    }

    //console.log("game upserted", upsertedGame);
    handleSaveSteps(upsertedGame, steps, draft);
  };

  const handleSaveSteps = async (upsertedGame, steps, draft) => {
    let gameId = upsertedGame.id;
    //console.log("steps to upsert", steps);
    let updatedSteps = [];

    for (let step of steps) {
      step.game_id = gameId;
      if (!step.id) {
        // Step insert
        const { data, error } = await supabase
          .from("game_steps")
          .insert(step)
          .select();
        if (error) {
          console.log("Error inserting step", error);
          showToast("Unable to create step, please contact support.", "danger");
          return;
        }

        //console.log("step", data);
        updatedSteps = [...updatedSteps, data[0]];
      } else {
        // Step update
        //console.log("updating step", step);
        const { data, error } = await supabase
          .from("game_steps")
          .update(step)
          .eq("id", step.id)
          .select();
        if (error) {
          console.log("Error updating step", error);
          showToast("Unable to update step, please contact support.", "danger");
          return;
        }

        updatedSteps = [...updatedSteps, data[0]];
      }
    }

    upsertedGame.game_steps = updatedSteps;

    //console.log("finally", upsertedGame);
    setGame(upsertedGame);

    handleDeleteSteps(draft);
  };

  const handleDeleteSteps = async (draft) => {
    //console.log("steps to delete", deletedSteps);
  
    for (let step of deletedSteps) {
      if (!step.id) {
        continue;
      }
  
      //console.log("Deleting step", step);
  
      const { data, error } = await supabase
        .from("game_steps")
        .delete()
        .eq("id", step.id);
  
      if (error) {
        console.log("Error deleting step", error);
        showToast("Unable to delete step, please contact support.", "danger");
        return;
      }
    }
  
    let message = draft ? "Game saved as draft!" : "Game published!";

    showToast(message, "success");
    setLoading(false);
  };

  // const handleSaveSteps = async (upsertedGame, steps) => {
  //   let gameId = upsertedGame.id;
  //   //console.log('steps to upsert', steps);
  //   steps.forEach((step) => {
  //     step.game_id = gameId;
  //     if (!step.id) {
  //       delete step.id;
  //     }
  //   })
  //   let upsertedSteps = await supabase.from('game_steps').insert(steps, { upsert: true }).select().then((res) => {
  //     //console.log('success', res)
  //   }).catch((error) => {
  //     //console.log('error', error)
  //   });
  //   try {
  //     upsertedSteps = upsertedSteps.data[0];
  //     upsertedGame.game_steps = upsertedSteps;
  //     setGame(upsertedGame);
  //     showToast('Game saved as draft!', 'success');
  //   } catch (error) {
  //     //console.log('Error upserting steps', error);
  //     showToast('Unable to create steps, please contact support.', 'danger');
  //   }
  // }

  const swapSteps = (firstIndex, secondIndex) => {
    const updatedSteps = [...game.game_steps];
    const tempStep = updatedSteps[firstIndex];
    updatedSteps[firstIndex] = updatedSteps[secondIndex];
    updatedSteps[secondIndex] = tempStep;
  
    updatedSteps[firstIndex].order = firstIndex + 1;
    updatedSteps[secondIndex].order = secondIndex + 1;
  
    // Call the function to update the game_steps in your state or database
    setGame((prevGame) => {
      return {
        ...prevGame,
        game_steps: updatedSteps,
      };
    });
  };

  return (
    <div className="upsert-game-container">
      <Modal
        show={showTestModal}
        size="lg"
        centered
        dialogClassName="game-screen-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Test Game</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GameScreen
            setView={undefined}
            gameName={game.game_name}
            instructions={game.instructions}
            gameAndSteps={getGameAndStepsFormat()}
            testMode={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeTestModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Row style={{ margin: 0 }}>
        <Header
          setView={setView}
          title="Create/Update Game"
          onBack={() => {
            setView("creatorAllGames");
          }}
        ></Header>
        <Col className="upsert-game-col small-padding">
          <Form>
            <Form.Group className="mb-3" controlId="gameName">
              <Form.Label>Game Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter game name"
                value={game.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Short Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                placeholder="Enter short description"
                value={game.description}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="instructions">
              <Form.Label>Instructions</Form.Label>
              <Form.Control
                as="textarea"
                name="instructions"
                rows={3}
                placeholder="Enter instructions"
                value={game.instructions}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <h3 style={{ textAlign: "center" }}>Steps</h3>
            {game.game_steps.map((step, index) => (
              <div key={index} className="step-container">
                <Row
                  style={{
                    marginTop: 0,
                    marginBottom: 10,
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                >
                  <Col xs={1} className="small-padding">
                    <div className="d-flex align-items-center justify-content-between" style={{ height: "100%", flexDirection: 'column'}}>
                      {index !== 0 && (
                        <div
                          className="move-up-step-icon d-flex align-items-center justify-content-center "
                          style={{ width: "50%", height: "100%" }}
                        >
                          <FiArrowUp
                            size={20}
                            onClick={() => swapSteps(index, index - 1)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      )}
                      {index !== game.game_steps.length - 1 && (
                        <div
                          className="move-down-step-icon d-flex align-items-center justify-content-center"
                          style={{ width: "50%", height: "100%" }}
                        >
                          <FiArrowDown
                            size={20}
                            onClick={() => swapSteps(index, index + 1)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      )}
                    </div>
                  </Col>



                  <Col xs={2} className="small-padding">
                    <Form.Group className="mb-1" controlId={`order${index}`}>
                      <Form.Label className="small">Order</Form.Label>
                      <Form.Control
                        size="sm"
                        readOnly
                        className="small"
                        type="number"
                        value={step.order}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6} className="small-padding">
                    <Form.Group className="mb-1" controlId={`stepName${index}`}>
                      <Form.Label className="small">Step Name</Form.Label>
                      <Form.Control
                        className="small"
                        size="sm"
                        type="text"
                        placeholder="Enter step name"
                        value={step.step_name}
                        onChange={(e) =>
                          updateStep(index, "step_name", e.target.value)
                        }
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2} className="small-padding">
                    <Form.Group className="mb-1" controlId={`role${index}`}>
                      <Form.Label className="small">Role</Form.Label>
                      <Form.Control
                        size="sm"
                        className="small"
                        as="select"
                        value={step.role}
                        onChange={(e) =>
                          updateStep(index, "role", e.target.value)
                        }
                        required
                      >
                        <option value="">Select role</option>
                        <option value="system">Command</option>
                        <option value="user">User</option>
                        <option value="assistant">Assistant</option>
                        <option value="game">Game</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={1} className="small-padding">
                    {index !== 0 && (
                      <Button
                        className="remove-step-btn"
                        variant="danger"
                        onClick={() => removeStep(index)}
                      >
                        <FiMinus />
                      </Button>
                    )}
                  </Col>
                </Row>
                {step.role === "system" && (
                  <Row style={{ margin: 0 }}>
                    <Col className="small-padding" xs={1}>

                    </Col>
                    <Col className="small-padding" xs={10}>
                      <Form.Group className="mb-1" controlId={`prompt${index}`}>
                        <Form.Label className="small">Prompt</Form.Label>
                        <Form.Control
                          size="sm"
                          className="small"
                          as="textarea"
                          rows={3}
                          placeholder="Enter prompt"
                          value={step.prompt}
                          onChange={(e) =>
                            updateStep(index, "prompt", e.target.value)
                          }
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </div>
            ))}
            <div className="mb-3 add-step-btn"></div>
            <Button
              className="mb-3 add-step-btn"
              variant="primary"
              onClick={() => addStep("system")}
            >
              Add command
            </Button>
            <Button
              className="mb-3 add-step-btn"
              variant="primary"
              onClick={() => addStep("user")}
            >
              Add user turn
            </Button>
            <Button
              className="mb-3 add-step-btn"
              variant="primary"
              onClick={() => addStep("assistant")}
            >
              Add AI turn
            </Button>
            <Button
              className="mb-3 add-step-btn"
              variant="primary"
              onClick={() => addStep("game")}
            >
              Add Game
            </Button>
            <div className="clearfix"></div>
            <hr />
            <div className="game-buttons">
              <Button
                variant="outline-secondary"
                className="mr-2"
                onClick={() => {
                  handleSaveGame(true);
                }}
                disabled={!isFormValid()}
              >
                Save Game Draft
              </Button>
              <Button
                variant="outline-secondary"
                className="mr-2"
                onClick={handleTestGame}
                disabled={!isFormValid()}
              >
                Test Game
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => {
                  handleSaveGame(false);
                }}
                disabled={!isFormValid()}
              >
                Publish Game
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default UpsertGame;
