// Header.js
import React from "react";
import "./Header.css";
import { FiInfo } from "react-icons/fi";
import { BiArrowBack } from "react-icons/bi";
import { Row, Col } from 'react-bootstrap';
import ZigzagHR from "./ZigzagHR";


const Header = ({ title, onBack, onInfo, setView }) => {
  return (
    <div className="header-container">
      <h1 className="website-title" onClick={() => setView("")}>
        <img src={`${process.env.PUBLIC_URL}/logo-removebg-preview.png`} alt="Logo" style={{height: "60px", width: "250px"}}/>
      </h1>
      <ZigzagHR></ZigzagHR>
      <Row className="header">
        <Col xs={2}>
          {onBack && (
            <button className="header-back-button" onClick={onBack}>
              <BiArrowBack size={24} />
            </button>
          )}
        </Col>
        <Col xs={8}>
          <h2 className="header-subtitle">{title}</h2>
        </Col>
        <Col xs={2}>
          {onInfo && (
            <button className="header-info-button" onClick={onInfo}>
              <FiInfo size={24} />
            </button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Header;
