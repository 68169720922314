import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { createClient } from '@supabase/supabase-js';
import { AuthContext, AuthProvider } from "./AuthContext";
import Header from './Header';

const Profile = ({setView}) => {
  const { loading, supabase, authenticated, user, profile, setProfile, setUser, setActiveSession, setSessionHasBeenSet } = React.useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [error, setError] = useState(null);

  const fieldsAreNotEmpty = () => {
    return username !== "";
  };

  const handleSave = async () => {
    setError(null);

    const { data, error } = await supabase
      .from('profile')
      .insert([{ id: user.id, username: username, creator: false }], { upsert: true }).select();

    if (error) {
      setError(error.message);
    } else {
      console.log('profile upsert', data)
      setProfile({ id: user.id, username: username });
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6} className="auth-col">
          <Header title="Profile" setView={profile && profile.id ? setView : () => { console.log('profile not set so not redirecting to main page')}}></Header>
          {/* <h2>Profile</h2> */}
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group className="mb-3" controlId="formUsername">
              <Form.Label>Username <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleSave} disabled={!fieldsAreNotEmpty()}>
              Save
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
