export const getOneWordSuggestion = async function () {

    let authHeader = getChatGPTAuthHeader();
    // //console.log('authHeader ' + authHeader);
  
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + authHeader);
    myHeaders.append('Content-Type', 'application/json');
  
    var raw = JSON.stringify({
      "model": "text-davinci-003",
      "prompt": "Please give me a random single word as a suggestion to an improv scene",
      "temperature": 0.7,
      "max_tokens": 60,
      "top_p": 1,
      "frequency_penalty": 0,
      "presence_penalty": 1
    });
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
  
    return fetch("https://api.openai.com/v1/completions", requestOptions)
      .then(response => response.text())
      .then(result => {
        //console.log('result ' + result);
        return result;
      })
      .catch(error => {
        console.log('error', error);
        throw error;
    });
};


export const sendPrompt = async function (prompt) {
    //console.log('prompt', prompt);

    let authHeader = getChatGPTAuthHeader();
    //console.log('authHeader ' + authHeader);
  
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + authHeader);
    myHeaders.append('Content-Type', 'application/json');
  
    var raw = JSON.stringify({
      "model": "gpt-3.5-turbo",
      "prompt": prompt,
      "temperature": 0.7,
      "max_tokens": 60,
      "top_p": 1,
      "frequency_penalty": 0,
      "presence_penalty": 1
    });
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
  
    return fetch("https://api.openai.com/v1/completions", requestOptions)
      .then(response => response.text())
      .then(result => {
        //console.log('result ' + result);
        return result;
      })
      .catch(error => {
        console.log('error', error);
        throw error;
    });
};

export const getRandomWord = async function () {
  return 'Coconut';
}

export const sendChat = async function (messages) {
  //console.log('messages ', messages);

  let authHeader = getChatGPTAuthHeader();
  // //console.log('authHeader ' + authHeader);

  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + authHeader);
  myHeaders.append('Content-Type', 'application/json');

  var raw = JSON.stringify({
    model: 'gpt-3.5-turbo',
    messages: messages,
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const maxRetries = 3;
  const timeoutMs = 30000;

  const fetchWithTimeout = async (url, options, timeout) => {
    return new Promise(async (resolve, reject) => {
      const timeoutId = setTimeout(() => reject(new Error('Request timeout')), timeout);

      try {
        const response = await fetch(url, options);
        clearTimeout(timeoutId);
        const result = await response.json();
        resolve(result);
      } catch (error) {
        clearTimeout(timeoutId);
        reject(error);
      }
    });
  };

  for (let i = 0; i < maxRetries; i++) {
    try {
      const result = await fetchWithTimeout('https://api.openai.com/v1/chat/completions', requestOptions, timeoutMs);
      return result;
    } catch (error) {
      if (i < maxRetries - 1) {
        console.warn(`Retry ${i + 1} failed: ${error.message}. Retrying...`);
      } else {
        console.error(`All ${maxRetries} retries have failed: ${error.message}`);
        throw new Error('AI query timeout');
      }
    }
  }
};




export const getGames = async function () {
  const apikey = getSupabaseAuthHeader();
  const authorization = getSupabaseAuthHeader();

  const url = `${getReactSupabaseURI()}/rest/v1/game?select=*`;

  //console.log('getGames.url', url)

  return fetch(url, {
    headers: {
      apikey: apikey,
      Authorization: 'Bearer ' + authorization,
      Range: '0-100',
    },
  })
    .then(response => response.json())
    .then(data => {
      return data;
    });
};

export const elevenTextToSpeechStream = async function (text) {
  const apikey = getElevenApiKey();
  const voiceId = 'bIBH20YUSGwzlt2scB2l';

  text = '     ' + text;

  const url = `${getElevenURI()}/${voiceId}/stream`;

  //console.log('elevenTextToSpeechStream.url', url)

  var myHeaders = new Headers();
  myHeaders.append("xi-api-key", apikey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "text": text,
    "voice_settings": {
      "stability": 0,
      "similarity_boost": 0
    }
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return fetch(url, requestOptions).then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const audioContext = new AudioContext();
    const source = audioContext.createMediaElementSource(new Audio());
    const audioDestination = audioContext.createMediaStreamDestination();
    source.connect(audioDestination);
    const reader = response.body.getReader();
    const audioChunks = [];

    function read() {
      reader.read().then(({done, value}) => {
        if (done) {
          audioContext.resume().then(() => {
            const audioBlob = new Blob(audioChunks, {type: 'audio/mpeg'});
            const audioUrl = URL.createObjectURL(audioBlob);
            const audioElement = new Audio(audioUrl);
            audioElement.play();
          });
        } else {
          audioChunks.push(value);
          read();
        }
      }).catch(error => {
        console.error('Error:', error);
      });
    }
    read();
  })
  .catch(error => {
    console.error('Error:', error);
  });
};

export const getGameAndSteps = async function (gameId) {
  const apikey = getSupabaseAuthHeader();
  const authorization = getSupabaseAuthHeader();

  const url = `${getReactSupabaseURI()}/rest/v1/rpc/get_game_with_steps`;

  var myHeaders = new Headers();
  myHeaders.append("apiKey", apikey);
  myHeaders.append("Authorization", "Bearer " + authorization);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "p_game_id": gameId
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return fetch(url, requestOptions)
    .then(response => response.json())
    .then(data => {
      return data;
    });
};

export const incrementGamePlays = async function (gameId) {
  const apikey = getSupabaseAuthHeader();
  const authorization = getSupabaseAuthHeader();

  const url = `${getReactSupabaseURI()}/rest/v1/rpc/increment_times_played`;

  var myHeaders = new Headers();
  myHeaders.append("apiKey", apikey);
  myHeaders.append("Authorization", "Bearer " + authorization);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "p_game_id": gameId
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return fetch(url, requestOptions)
    .then(response => response)
    .then(data => {
      return data;
    });
};

export const updateGameTotalTokenSpent = async function (gameId, tokens) {
  const apikey = getSupabaseAuthHeader();
  const authorization = getSupabaseAuthHeader();

  const url = `${getReactSupabaseURI()}/rest/v1/rpc/update_game_total_tokens_spent`;

  var myHeaders = new Headers();
  myHeaders.append("apiKey", apikey);
  myHeaders.append("Authorization", "Bearer " + authorization);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "p_game_id": gameId, 
    "tokens": tokens
  });

  //console.log('raw', raw)

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return fetch(url, requestOptions)
    .then(response => response)
    .then(data => {
      return data;
    });
};

function getChatGPTAuthHeader() {
    if (process.env.REACT_APP_CHAT_GPT_AUTH_TOKEN) {
        return process.env.REACT_APP_CHAT_GPT_AUTH_TOKEN;
    } else {
        throw new Error('REACT_APP_CHAT_GPT_AUTH_TOKEN is blank');
    }
}

function getSupabaseAuthHeader() {
  if (process.env.REACT_APP_SUPABASE_AUTH_TOKEN) {
      return process.env.REACT_APP_SUPABASE_AUTH_TOKEN;
  } else {
      throw new Error('REACT_APP_SUPABASE_AUTH_TOKEN is blank');
  }
}

function getReactSupabaseURI() {
  if (process.env.REACT_APP_SUPABASE_URI) {
      return process.env.REACT_APP_SUPABASE_URI;
  } else {
      throw new Error('REACT_APP_SUPABASE_URI is blank');
  }
}

function getElevenApiKey() {
  if (process.env.REACT_APP_ELEVEN_API_KEY) {
      return process.env.REACT_APP_ELEVEN_API_KEY;
  } else {
      throw new Error('REACT_APP_ELEVEN_API_KEY is blank');
  }
}

function getElevenURI() {
  if (process.env.REACT_APP_ELEVEN_URI) {
      return process.env.REACT_APP_ELEVEN_URI;
  } else {
      throw new Error('REACT_APP_ELEVEN_URI is blank');
  }
}