import React, { useState, useEffect } from "react";
// import { useNavigate, useLocation } from 'react-router-dom';
import { Modal, Button, FormControl } from "react-bootstrap";
import {
  sendChat,
  incrementGamePlays,
  updateGameTotalTokenSpent,
  elevenTextToSpeechStream,
} from "../Utility";
import { MdRefresh } from "react-icons/md";

import "./GameScreen.css";
import Header from "./Header";
import UserInput from "./UserInput";
import ControlPanel from "./ControlPanel";
// import { setTimeout } from 'timers';

const GameScreen = ({
  setView,
  gameName,
  instructions,
  gameAndSteps,
  testMode,
}) => {
  // const startPrompt = location?.state?.startPrompt || '';

  // //console.log("gameName:", gameName);
  // //console.log("instructions:", instructions);
  // //console.log("gameAndSteps:", gameAndSteps);
  // //console.log("testMode:", testMode);

  // const shouldAiStart = () => {
  //   return gameAndSteps[1].step_role == 'assistant'
  // }

  // //console.log('gameAndSteps', gameAndSteps)

  // const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  // const [aiStart, setAiStart] = useState(shouldAiStart());
  const [aiTurn, setAiTurn] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isGameDone, setIsGameDone] = useState(false);
  const [firstRun, setFirstRun] = useState(true);
  const [gameModeActive, setGameModeActive] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [tokensForMessage, setTokensForMessage] = useState(0);
  const [enableVoice, setEnableVoice] = useState(false);

  const handleStep = () => {
    let currentStep = gameAndSteps[currentStepIndex];
    //console.log("handleStep.currentStep", currentStepIndex, currentStep);

    if ((currentStep && currentStep.step_role == "game") || gameModeActive) {
      setAiTurn(!aiTurn);
      setGameModeActive(true);
    } else {
      if (currentStep.step_role == "assistant") {
        //console.log("assistant step");
        setAiTurn(true);
      } else if (currentStep.step_role == "user") {
        //console.log("user step");
        setAiTurn(false);
      } else if (currentStep.step_role == "system") {
        handleSystemStep(currentStep);
      }
    }
  };

  const handleSystemStep = (currentStep) => {
    //console.log("handling system step");
    setMessages([
      ...messages,
      { role: "system", content: currentStep.step_prompt },
    ]);
  };

  useEffect(() => {
    if (messages.length > 0) {
      //TODO validate latest message is correct
      //console.log("messages has been updated", messages);
      let gameDone = checkGameDone();
      setIsGameDone(gameDone);
      if (!gameDone) {
        setCurrentStepIndex(currentStepIndex + 1);
      }
    }
  }, [messages]);

  useEffect(() => {
    //console.log("current step updated, handling next step");
    handleStep();
  }, [currentStepIndex]);

  useEffect(() => {
    if (tokensForMessage > 0 && !testMode) {
      if (!gameStarted) {
        //call function to increment game numbers
        incrementGamePlays(gameAndSteps[0].game_id)
          .then(() => {
            //console.log("game plays incremented");
          })
          .catch(() => {
            console.error("game plays not incremented");
          });
        setGameStarted(true);
      }

      //increment game total tokens used
      updateGameTotalTokenSpent(gameAndSteps[0].game_id, tokensForMessage)
        .then(() => {
          //console.log("tokens spent incremented");
        })
        .catch((error) => {
          console.error("tokens spent incremented", error);
        });
    }
  }, [tokensForMessage]);

  useEffect(() => {
    if (aiTurn === true) {
      //console.log("ai turn now");
      getAiResponse();
    }
  }, [aiTurn]);

  const handleSendMessage = () => {
    //console.log("handleSendMessage");
    if (inputValue.trim()) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: Date.now(), role: "user", content: inputValue },
      ]);
      setInputValue("");
    }
  };

  const checkGameDone = () => {
    if (
      gameAndSteps[gameAndSteps.length - 1].step_role != "game" &&
      gameAndSteps[currentStepIndex + 1] == null
    ) {
      //console.log("Game is done");
      return true;
    } else {
      return false;
    }
  };

  const getAiResponse = () => {
    //console.log("getAiResponse");
    let messagesWithGamePrompt = [...removeIdParam()];

    // parseAiResponse({choices: [{message: {role: 'assistant', content: 'ai test'}}], usage: {total_tokens: 1}})
    sendChat(messagesWithGamePrompt)
      .then((response) => {
        parseAiResponse(response);
      })
      .catch((err) => {
        throw err;
      });
  };

  const parseAiResponse = (response) => {
    // //console.log('ai response', response);

    let responseMessage = response.choices[0].message;
    //console.log("responseMessage", responseMessage);
    responseMessage.id = Date.now();

    let content = responseMessage.content;

    if (enableVoice) {
      elevenTextToSpeechStream(content)
        .then(() => {
          setTimeout(() => {
            setMessages((prevMessages) => [...prevMessages, responseMessage]);
          }, 1000);
        })
        .catch((err) => {
          console.log("error playing voice", err);
          setMessages((prevMessages) => [...prevMessages, responseMessage]);
        });
    } else {
      setMessages((prevMessages) => [...prevMessages, responseMessage]);
    }

    setTokensForMessage(Number(response.usage.total_tokens));

    // //console.log('responseMessage', responseMessage);
  };

  const removeIdParam = () => {
    return messages.map((message) => {
      return { role: message.role, content: message.content };
    });
  };

  const handleBack = () => {
    if (
      window.confirm(
        "Are you sure you want to go back? This will end the current game."
      )
    ) {
      setView("");
    }
  };

  const handleInfo = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const restartGame = () => {
    setAiTurn(false);
    setMessages([]);
    setIsGameDone(false);
    setGameModeActive(false);
    // setAiTurn(aiStart);
    setCurrentStepIndex(0);
    setGameStarted(false);
    setTokensForMessage(0);
  };

  // if (firstRun) { //means game just started
  //   setFirstRun(false);
  //   handleStep();
  // }

  return (
    <div className="game-screen">
      {!testMode &&
      <Header
        setView={setView}
        title={gameName}
        onBack={handleBack}
        onInfo={handleInfo}
      ></Header>}

      <div className="game-layout">
        <div className="game-content">
          {messages.map((message, index) =>
            (message.role != "system") != 0 ? (
              <div
                key={message.id}
                className={`chat-bubble ${
                  message.role === "user" ? "user-bubble" : "ai-bubble"
                }`}
              >
                <p>{message.content}</p>
              </div>
            ) : null
          )}
          {isGameDone ? (
            <div>
              <p style={{ textAlign: "center", marginTop: "50px" }}>
                Game is done
              </p>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button variant="primary" onClick={restartGame}>
                  <MdRefresh size={20} style={{ marginRight: "5px" }} />
                  Restart Game
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <p style={{ margin: "30px 0px 10px 0px" }}>
                {aiTurn ? "AI's turn" : "Your turn"}
              </p>
              {!aiTurn && (
                <div className="input-container">
                  {/* <FormControl
                  as="textarea"
                  rows={3}
                  value={inputValue}
                  onChange={handleMessageChange}
                  placeholder="Type your response..."
                /> */}
                  <UserInput
                    handleChange={setInputValue}
                    handleSendMessage={handleSendMessage}
                  ></UserInput>
                </div>
              )}
            </div>
          )}
        </div>
        <ControlPanel
          enableAIVoice={enableVoice}
          setEnableAIVoice={setEnableVoice}
        />
      </div>

      <Modal
        show={showModal}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>How to Play</Modal.Title>
        </Modal.Header>
        <Modal.Body>{instructions}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GameScreen;
