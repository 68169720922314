// CreatorAllGames.js
import React, { useState, useEffect } from "react";
import { Container, Table, Button } from "react-bootstrap";
import { getGames } from "../Utility";
import Loader from "./Loader";
import "./CreatorAllGames.css";
import Header from "./Header";
import { useTable, useSortBy } from "react-table";

const CreatorAllGames = ({ setView }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Instructions",
        accessor: "instructions",
      },
      {
        Header: "Draft?",
        accessor: "draft",
        Cell: ({ value }) => (value ? "Yes" : "No"),
      },
      {
        Header: "Times Played",
        accessor: "times_played",
      },
      {
        Header: "Total Tokens Spent",
        accessor: "total_tokens_spent",
      },
    ],
    []
  );
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchGames = async () => {
      setIsLoading(true);
      const fetchedGames = await getGames();

      //console.log("fetchedGames", fetchedGames);
      setGames(fetchedGames);
      setIsLoading(false);
    };

    fetchGames();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  const handleRowClick = (gameId) => {
    setView("upsertGame", { gameId: gameId });
  };

  const TableComponent = ({ columns, data }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({ columns, data }, useSortBy);

    return (
      <table
        {...getTableProps()}
        className="table table-bordered table-hover games-table"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " ↓"
                        : " ↑"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => handleRowClick(row.original.id)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <Container
      className="creator-all-games-container"
      style={{ maxWidth: "500px" }}
    >
      <Header title="Creator All Games" setView={setView}></Header>
      <TableComponent columns={columns} data={games} />
      <div className="clearfix"></div>
      <hr />
      <div className="game-buttons">
        <Button
          variant="primary"
          className="mr-2"
          onClick={() => setView("upsertGame")}
        >
          Create New Game
        </Button>
      </div>
    </Container>
  );
};

export default CreatorAllGames;
