import React, { useState, useEffect } from 'react';
import './ZigzagHR.css';

const ZigzagHR = ({ widthRatio = 0.9 }) => {
  const [points, setPoints] = useState('');
  const [viewBox, setViewBox] = useState('0 0 0 0');

  useEffect(() => {
    const calculatePoints = () => {
      const containerWidth = document.documentElement.clientWidth;
      const lineWidth = containerWidth * widthRatio;
      const segmentWidth = 20;
      const segmentHeight = 20;
      const numSegments = Math.ceil(lineWidth / segmentWidth);
      let pointsStr = '';

      for (let i = 0; i <= numSegments; i++) {
        const x = (containerWidth - lineWidth) / 2 + i * segmentWidth;
        const y = (i % 2 === 0) ? segmentHeight : 0;
        pointsStr += `${x},${y} `;
      }

      setPoints(pointsStr);
      setViewBox(`0 0 ${containerWidth} ${segmentHeight * 2}`);
    };

    calculatePoints();
    window.addEventListener('resize', calculatePoints);

    return () => {
      window.removeEventListener('resize', calculatePoints);
    };
  }, [widthRatio]);

  return (
    <div className="zigzag-container">
        <svg className="zigzag-hr" width="100%" height="20" viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
        <polyline points={points} fill="none" stroke="black" strokeWidth="2" />
        </svg>
    </div>
  );
};

export default ZigzagHR;
