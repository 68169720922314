import React, { useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
import Header from "./Header";
import "./GameInfoStart.css";
import { getGameAndSteps } from "../Utility";
import Spinner from "react-bootstrap/Spinner";
import {
  Button
} from "react-bootstrap";

const GameInfoStart = ({ setView, gameId }) => {
  // const navigate = useNavigate();
  // const location = useLocation();
  //console.log("gameId ", gameId);

  const [loading, setLoading] = useState(true);
  const [gameAndSteps, setGameAndSteps] = useState(null);
  const [gameName, setGameName] = useState();
  const [instructions, setInstructions] = useState();

  if (!gameAndSteps) {
    if (!gameId) {
      //console.log("no game id found, redirecting back to home");
      // navigate("/");
    } else {
      getGameAndSteps(gameId)
        .then((result) => {
          //console.log("gameAndSteps ", result);
          setGameAndSteps(result);
          setGameName(result[0].game_name);
          setInstructions(result[0].game_instructions);
          setLoading(false);
        })
        .catch((err) => {
          console.error("error querying game and steps ", err);
          setLoading(false);
        });
    }
  }

  const handleBack = () => {
    setView("");
  };

  const handleStart = () => {
    const newProps = {
      gameName: gameName,
      instructions: instructions,
      gameAndSteps: gameAndSteps,
      testMode: false,
    };
    setView("gameScreen", newProps);
  };

  return (
    <div className="game-info-start">
      {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <Header setView={setView} title={gameName} onBack={handleBack} />
          <section style={{width: '100%'}}>
            <h4 style={{ marginBottom: "20px" }}>How to Play</h4>
            <p style={{ whiteSpace: "pre-line" }}>{instructions}</p>
          </section>
          <Button
            className="start-button"
            onClick={handleStart}
            style={{ marginTop: "20px" }}
          >
            Start
          </Button>
        </>
      )}
    </div>
  );
};

export default GameInfoStart;
