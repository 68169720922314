import React, { useState } from "react";
import { AuthContext, AuthProvider } from "./components/AuthContext";
import AllGames from "./components/AllGames";
import GameInfoStart from "./components/GameInfoStart";
import GameScreen from "./components/GameScreen";
import AuthScreen from "./components/AuthScreen";
import Loader from "./components/Loader";
import Profile from "./components/Profile";
import UpsertGame from "./components/UpsertGame";
import HomePage from "./components/HomePage";
import CreatorAllGames from "./components/CreatorAllGames";
import { ToastProvider } from './components/ToastComponent';


function AppContent() {
  const [componentProps, setComponentProps] = React.useState({});
  const [currentView, setCurrentView] = useState("auth");
  const { loading, supabase, authenticated, user, profile, setProfile } = React.useContext(AuthContext);

  const setView = (newView, props = {}) => {
    setCurrentView(newView);
    setComponentProps(props);
  };

  React.useEffect(() => {
    if (authenticated) {
      if (!profile) {
        setView("profile");
      } else {
        setView("");
      }
    } else {
      setView("auth");
    }
  }, [authenticated, profile]);

  const renderView = () => {
    if (loading) {
      return <Loader />;
    }
    switch (currentView) {
      case "allGames":
        return <AllGames setView={setView} />;
      case "gameInfoStart":
        return <GameInfoStart setView={setView} {...componentProps}/>;
      case "gameScreen":
        return <GameScreen setView={setView} {...componentProps}/>;
      case "auth":
        return <AuthScreen setView={setView} />;
      case "profile":
        return <Profile setView={setView} />;
      case "creatorAllGames":
        return <CreatorAllGames setView={setView} />;
      case "upsertGame":
        return <UpsertGame setView={setView} {...componentProps}/>;
      default:
        return <HomePage setView={setView} />;
    }
  };

  return <div className="App">{renderView()}</div>;
}

function App() {
  return (
    <ToastProvider>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </ToastProvider>
  );
}

export default App;
